@font-face {
  font-family: 'inter-ui--regular';
  src: url("../fonts/inter-ui--regular/inter-ui--regular.eot");
  src: url("../fonts/inter-ui--regular/inter-ui--regular.eot?#iefix") format('embedded-opentype'), url("../fonts/inter-ui--regular/inter-ui--regular.woff") format('woff'), url("../fonts/inter-ui--regular/inter-ui--regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'inter-ui--italic';
  src: url("../fonts/inter-ui--italic/inter-ui--italic.eot");
  src: url("../fonts/inter-ui--italic/inter-ui--italic.eot?#iefix") format('embedded-opentype'), url("../fonts/inter-ui--italic/inter-ui--italic.woff") format('woff'), url("../fonts/inter-ui--italic/inter-ui--italic.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'inter-ui--medium';
  src: url("../fonts/inter-ui--medium/inter-ui--medium.eot");
  src: url("../fonts/inter-ui--medium/inter-ui--medium.eot?#iefix") format('embedded-opentype'), url("../fonts/inter-ui--medium/inter-ui--medium.woff") format('woff'), url("../fonts/inter-ui--medium/inter-ui--medium.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
:root {
  --ratio: 1.12;
  --fz-ratio-power--four: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--three: calc(1rem * var(--ratio) * var(--ratio) * var(--ratio));
  --fz-ratio-power--two: calc(1rem * var(--ratio) * var(--ratio));
  --fz-ratio-power--one: calc(1rem * var(--ratio));
  --fz-ratio-power--minus-one: calc(1rem / var(--ratio));
  --fz-ratio-power--minus-two: calc((1rem / var(--ratio)) / var(--ratio));
  --sp-ratio-power--four: calc(var(--fz-ratio-power--four) * 1.4);
  --sp-ratio-power--three: calc(var(--fz-ratio-power--three) * 1.4);
  --sp-ratio-power--two: calc(var(--fz-ratio-power--two) * 1.4);
  --sp-ratio-power--one: calc(var(--fz-ratio-power--one) * 1.4);
}
@media only screen and (min-width: 681px) {
  :root {
    --ratio: 1.2;
  }
}
@media only screen and (min-width: 1281px) {
  :root {
    --ratio: 1.28;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action-anchor-interaction-forwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action-anchor-interaction-backwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-0.539333333333333rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-moz-keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-webkit-keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
@-o-keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes action-anchor-interaction-downwards {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateY(0.3236rem);
  }
  100% {
    transform: translateX(0);
  }
}
.contain {
  display: block;
  width: 92%;
  max-width: 1520px;
  margin-right: auto;
  margin-left: auto;
}
html * {
  will-change: outline-offset;
  transition-duration: 0.28s;
  transition-property: outline-offset;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  outline-offset: 0;
}
html[data-focus-source="key"] *:focus,
html[data-focus-source="script"] *:focus {
  z-index: 100;
  outline-offset: 6px;
  outline: 2px dashed #fb3;
  overflow: visible;
}
html[data-focus-source="pointer"] *:focus,
html[data-focus-source=""] *:focus {
  outline: 0;
}
*,
*::before,
*::after {
  box-sizing: border-box;
  word-wrap: break-word;
}
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
@media only screen and (min-width: 881px) {
  body {
    height: 100vh;
  }
}
@media only screen and (min-width: 881px) {
  body {
    max-height: 100vh;
  }
}
@media only screen and (min-width: 881px) {
  body {
    overflow: hidden;
  }
}
img {
  display: block;
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
  vertical-align: bottom;
}
svg {
  overflow: visible;
  height: 100%;
  max-height: 100%;
  width: auto;
  max-width: 100%;
}
input,
button {
  will-change: color, background-color, box-shadow, transform, opacity;
  transition-duration: 0.28s;
  transition-property: color, background-color, box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 0;
  font: inherit;
  color: inherit;
}
.reference_colours {
  display: flex;
  flex-direction: column;
  width: 100%;
}
* + .reference_colours {
  margin-top: 1.618rem;
}
.reference_colours__list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}
* + .reference_colours__list {
  margin-top: 1.618rem;
}
.reference_colours__item {
  display: block;
  width: 18.4%;
  border: 2px solid #fff;
}
.reference_colours__item::after {
  content: '';
  display: block;
  width: 100%;
  padding-bottom: 100%;
}
.reference_colours__item:nth-child(n+6) {
  margin-top: 2%;
}
* + .reference_colours__item {
  margin-left: 2%;
}
.cl--interaction {
  background-color: #319b98;
}
.cl--interaction--dark {
  background-color: #2b6462;
}
.cl--interaction--low {
  background-color: #2b9794;
}
.cl--interaction--med {
  background-color: #7bc6c4;
}
.cl--interaction--high {
  background-color: #daf1f0;
}
.cl--attention {
  background-color: #fb3;
}
.cl--attention--dark {
  background-color: #eb9c00;
}
.cl--attention--low {
  background-color: #fa0;
}
.cl--attention--med {
  background-color: #fc6;
}
.cl--attention--high {
  background-color: #fec;
}
.cl--interface {
  background-color: #3f4450;
}
.cl--interface--dark {
  background-color: #18191b;
}
.cl--interface--low {
  background-color: #373b43;
}
.cl--interface--med {
  background-color: #dfe2e7;
}
.cl--interface--light {
  background-color: #e7eaef;
}
.cl--interface--high {
  background-color: #f4f6f9;
}
.cl--text {
  background-color: #50545e;
}
.cl--text--dark {
  background-color: #212327;
}
.cl--text--low {
  background-color: #3d4048;
}
.cl--text--med {
  background-color: #5e636e;
}
.cl--text--light {
  background-color: #e7eaef;
}
.cl--text--high {
  background-color: #f4f6f9;
}
:root {
  font-size: 12px;
}
@media screen and (min-width: 480px) {
  :root {
    font-size: calc(0.5vw + 9.6px);
  }
}
@media screen and (min-width: 1680px) {
  :root {
    font-size: 18px;
  }
}
html {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
select,
input,
textarea,
button {
  font-family: 'inter-ui--regular', sans-serif;
  font-weight: 400;
  line-height: 1.618;
  letter-spacing: -0.03em;
  font-size: 1rem;
  color: #50545e;
}
body::-webkit-input-placeholder,
select::-webkit-input-placeholder,
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
button::-webkit-input-placeholder {
  opacity: 1;
  color: #50545e;
}
body:-moz-placeholder,
select:-moz-placeholder,
input:-moz-placeholder,
textarea:-moz-placeholder,
button:-moz-placeholder {
  opacity: 1;
  color: #50545e;
}
body::-moz-placeholder,
select::-moz-placeholder,
input::-moz-placeholder,
textarea::-moz-placeholder,
button::-moz-placeholder {
  opacity: 1;
  color: #50545e;
}
body:-ms-input-placeholder,
select:-ms-input-placeholder,
input:-ms-input-placeholder,
textarea:-ms-input-placeholder,
button:-ms-input-placeholder {
  opacity: 1;
  color: #50545e;
}
h1,
h2,
h3,
h4 {
  display: block;
  line-height: 1.4;
  font-family: 'inter-ui--medium', sans-serif;
  font-weight: 400;
  color: #3d4048;
}
h1 a,
h2 a,
h3 a,
h4 a,
h1 a:visited,
h2 a:visited,
h3 a:visited,
h4 a:visited {
  color: #319b98;
}
h1 a:active,
h2 a:active,
h3 a:active,
h4 a:active,
h1 a:visited:active,
h2 a:visited:active,
h3 a:visited:active,
h4 a:visited:active,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h1 a:visited:hover,
h2 a:visited:hover,
h3 a:visited:hover,
h4 a:visited:hover,
h1 a:focus,
h2 a:focus,
h3 a:focus,
h4 a:focus,
h1 a:visited:focus,
h2 a:visited:focus,
h3 a:visited:focus,
h4 a:visited:focus {
  color: #2b9794;
}
h1,
h2 {
  letter-spacing: -0.048em;
}
h3,
h4 {
  letter-spacing: -0.034em;
}
h1 {
  max-width: 37ch;
  font-size: 1.57351936rem;
  font-size: var(--fz-ratio-power--four);
}
* + h1 {
  margin-top: 2.427rem;
}
@media only screen and (min-width: 681px) {
  h1 {
    font-size: 2.073600000000001rem;
    font-size: var(--fz-ratio-power--four);
  }
}
@media only screen and (min-width: 1281px) {
  h1 {
    font-size: 2.68435456rem;
    font-size: var(--fz-ratio-power--four);
  }
}
h2 {
  max-width: 44.4ch;
  font-size: 1.404928rem;
  font-size: var(--fz-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  h2 {
    font-size: 1.728000000000001rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  h2 {
    font-size: 2.097152rem;
    font-size: var(--fz-ratio-power--three);
  }
}
* + h2 {
  margin-top: 1.966899200000001rem;
  margin-top: var(--sp-ratio-power--three);
}
@media only screen and (min-width: 681px) {
  * + h2 {
    margin-top: 2.419200000000001rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  * + h2 {
    margin-top: 2.9360128rem;
    margin-top: var(--sp-ratio-power--three);
  }
}
h3 {
  max-width: 51.8ch;
  font-size: 1.2544rem;
  font-size: var(--fz-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  h3 {
    font-size: 1.44rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  h3 {
    font-size: 1.6384rem;
    font-size: var(--fz-ratio-power--two);
  }
}
* + h3 {
  margin-top: 1.75616rem;
  margin-top: var(--sp-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  * + h3 {
    margin-top: 2.016rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  * + h3 {
    margin-top: 2.29376rem;
    margin-top: var(--sp-ratio-power--two);
  }
}
h4 {
  max-width: 59.2ch;
  font-size: 1.12rem;
  font-size: var(--fz-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  h4 {
    font-size: 1.2rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  h4 {
    font-size: 1.28rem;
    font-size: var(--fz-ratio-power--one);
  }
}
* + h4 {
  margin-top: 1.568rem;
  margin-top: var(--sp-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  * + h4 {
    margin-top: 1.68rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  * + h4 {
    margin-top: 1.792rem;
    margin-top: var(--sp-ratio-power--one);
  }
}
p {
  width: auto;
  max-width: 100%;
  font-family: 'inter-ui--regular', sans-serif;
  font-size: 1rem;
  line-height: 1.618;
}
* + p {
  margin-top: 1.618rem;
}
h2 + p,
h3 + p,
h4 + p {
  margin-top: 1.077588rem;
}
.reading_content p,
p.reading_content {
  width: 100%;
  max-width: 74ch;
}
.reading_content p a,
p.reading_content a,
.reading_content p a:visited,
p.reading_content a:visited {
  box-shadow: inset 0 -3px rgba(49,155,152,0.2);
  color: #319b98;
  color: #319b98;
}
.reading_content p a:active,
p.reading_content a:active,
.reading_content p a:visited:active,
p.reading_content a:visited:active,
.reading_content p a:focus,
p.reading_content a:focus,
.reading_content p a:visited:focus,
p.reading_content a:visited:focus,
.reading_content p a:hover,
p.reading_content a:hover,
.reading_content p a:visited:hover,
p.reading_content a:visited:hover {
  box-shadow: inset 0 -1px rgba(43,151,148,0.6);
  color: #2b9794;
}
.reading_content p a:active,
p.reading_content a:active,
.reading_content p a:visited:active,
p.reading_content a:visited:active,
.reading_content p a:hover,
p.reading_content a:hover,
.reading_content p a:visited:hover,
p.reading_content a:visited:hover,
.reading_content p a:focus,
p.reading_content a:focus,
.reading_content p a:visited:focus,
p.reading_content a:visited:focus {
  color: #2b9794;
}
a,
a:visited {
  will-change: color, box-shadow;
  transition-duration: 0.28s;
  transition-property: color, box-shadow;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
  text-decoration: none;
}
b,
strong {
  font-family: 'inter-ui--medium', sans-serif;
  font-weight: $ff--normal;
}
i,
em {
  font-family: 'inter-ui--italic', sans-serif;
  font-weight: $ff--normal;
}
::selection {
  background-color: #373b43;
  text-shadow: 0 0 0.08em #18191b;
  color: #fff;
}
.reading_content {
  width: 100%;
}
.reading_content ul,
.reading_content ol,
.reading_content dl {
  display: block;
  width: 100%;
  max-width: 74ch;
  padding-left: 3ch;
}
* + .reading_content ul,
* + .reading_content ol,
* + .reading_content dl {
  margin-top: 1.077588rem;
}
.reading_content ul {
  list-style-type: disc;
}
.reading_content ul ul {
  list-style-type: circle;
}
.reading_content ul ul ul {
  list-style-type: square;
}
.reading_content ol {
  list-style-type: decimal;
}
.reading_content ol ol {
  list-style-type: lower-alpha;
}
.reading_content ol ol ol {
  list-style-type: lower-roman;
}
.reading_content li {
  max-width: 100%;
}
* + .reading_content li {
  margin-top: 0.809rem;
}
sup {
  vertical-align: super;
  font-size: 1.12rem;
  font-size: var(--fz-ratio-power--one);
}
@media only screen and (min-width: 681px) {
  sup {
    font-size: 1.2rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  sup {
    font-size: 1.28rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.application_container {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}
@media only screen and (min-width: 1081px) {
  .application_container {
    flex-direction: row;
  }
}
@media only screen and (min-width: 881px) {
  .application_container {
    max-height: 100vh;
  }
}
.global_footer {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-shadow: inset 0 1px 3px #dfe2e7;
  background-color: #e7eaef;
  padding: 0.539333333333333rem 4vw;
  line-height: 1.4;
}
.global_footer__navigation__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.global_footer__navigation__item {
  margin-bottom: 0.4045rem;
}
* + .global_footer__navigation__item {
  margin-left: 0.4045rem;
}
.global_footer__navigation__item:not(:last-child) {
  margin-right: 0.4045rem;
}
.global_footer__navigation__anchor {
  will-change: box-shadow, color;
  transition-duration: 0.28s;
  transition-property: box-shadow, color;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.79719387755102rem;
  font-size: var(--fz-ratio-power--minus-two);
  box-shadow: inset 0 -3px rgba(49,155,152,0.2);
  color: #319b98;
  letter-spacing: 0.014em;
}
@media only screen and (min-width: 681px) {
  .global_footer__navigation__anchor {
    font-size: 0.694444444444444rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .global_footer__navigation__anchor {
    font-size: 0.6103515625rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
.global_footer__navigation__anchor:active,
.global_footer__navigation__anchor:focus,
.global_footer__navigation__anchor:hover {
  box-shadow: inset 0 -1px rgba(43,151,148,0.6);
  color: #2b9794;
}
.global_footer__copyright {
  display: block;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.4;
  font-size: 0.79719387755102rem;
  font-size: var(--fz-ratio-power--minus-two);
  letter-spacing: 0.014em;
  color: #212327;
}
@media only screen and (min-width: 681px) {
  .global_footer__copyright {
    font-size: 0.694444444444444rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
@media only screen and (min-width: 1281px) {
  .global_footer__copyright {
    font-size: 0.6103515625rem;
    font-size: var(--fz-ratio-power--minus-two);
  }
}
.global_footer__copyright a,
.global_footer__copyright a:visited {
  will-change: box-shadow, color;
  transition-duration: 0.28s;
  transition-property: box-shadow, color;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  box-shadow: inset 0 -3px rgba(49,155,152,0.2);
  color: #319b98;
}
.global_footer__copyright a:active,
.global_footer__copyright a:visited:active,
.global_footer__copyright a:focus,
.global_footer__copyright a:visited:focus,
.global_footer__copyright a:hover,
.global_footer__copyright a:visited:hover {
  box-shadow: inset 0 -1px rgba(43,151,148,0.6);
  color: #2b9794;
}
.global_header {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #18191b;
  background-color: #373b43;
}
@media only screen and (min-width: 1081px) {
  .global_header {
    flex: 0 0 16rem;
  }
}
@media only screen and (min-width: 881px) {
  .global_header {
    height: 100vh;
    max-height: 100vh;
  }
}
.global_navigation {
  display: flex;
  flex-direction: column;
  overflow: visible;
}
@media only screen and (min-width: 1081px) {
  .global_navigation {
    flex: 1 1 auto;
  }
}
.global_navigation__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #319b98;
  padding: 1.077588rem;
  text-shadow: 0 0 0.08em #2b6462;
  text-align: center;
  color: #fff;
}
@media only screen and (min-width: 1081px) {
  .global_navigation__button {
    display: none;
  }
}
.global_navigation__button_open_text {
  display: inline-block;
}
.global_navigation__button[open] .global_navigation__button_open_text {
  display: none;
}
.global_navigation__button_close_text {
  display: none;
}
.global_navigation__button[open] .global_navigation__button_close_text {
  display: inline-block;
}
.global_navigation__button_navigation_text {
  display: inline-block;
  margin-right: 0.539333333333333rem;
  margin-left: 0.269666666666667rem;
}
.global_navigation__button_arrow {
  width: 1.077588rem;
  height: 1.077588rem;
}
.global_navigation__button_arrow_icon {
  will-change: transform;
  transition-duration: 0.28s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.global_navigation__button[open] .global_navigation__button_arrow_icon {
  transform: scaleX(-1) scaleY(-1);
}
.global_navigation__button_arrow_icon_path {
  fill: #2b6462;
}
.global_navigation__list {
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  border-top: 1px solid #2b6462;
  font-size: 0.892857142857143rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 1081px) {
  .global_navigation__list {
    display: flex;
  }
}
@media only screen and (min-width: 1081px) {
  .global_navigation__list {
    border-top: 0;
  }
}
@media only screen and (min-width: 681px) {
  .global_navigation__list {
    font-size: 0.833333333333333rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .global_navigation__list {
    font-size: 0.78125rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.global_navigation__list[open] {
  display: flex;
}
.global_navigation__item {
  flex: 0 0 auto;
  display: flex;
}
.global_navigation__item,
.global_navigation__item > * {
  width: 100%;
}
.global_navigation__item:not(:last-child) {
  border-bottom: 1px solid #2b6462;
}
.global_navigation__item--signout {
  margin-top: 1.618rem;
}
.global_navigation__item--pages {
  border-bottom: 1px solid #2b6462;
}
.global_navigation__item--pages:first-child {
  margin-top: 1.618rem;
}
.global_navigation__anchor {
  will-change: background-color;
  transition-duration: 0.28s;
  transition-property: background-color;
  transition-timing-function: 0.14s;
  position: relative;
  z-index: auto;
  display: inline-block;
  width: 100%;
  background-color: #319b98;
  padding: 0.809rem;
  text-shadow: 0 0 0.1em #2b6462;
  text-align: left;
  color: #fff;
}
.global_navigation__anchor__bright {
  background-color: #fb3;
  color: #3f4450;
  text-shadow: 0 0;
}
.global_navigation__anchor--current_url {
  box-shadow: inset 3px 0 0 #fb3;
}
@media only screen and (min-width: 1081px) {
  .global_navigation__anchor--current_url {
    box-shadow: inset -3px 0 0 #fb3;
  }
}
.global_navigation__anchor__inner {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.global_navigation__anchor__arrow {
  will-change: margin-left;
  transition-duration: 0.28s;
  transition-property: margin-left;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  margin-left: -0.7rem;
  height: 0.539333333333333rem;
  width: 0.539333333333333rem;
}
.global_navigation__anchor:active .global_navigation__anchor__arrow,
.global_navigation__anchor:focus .global_navigation__anchor__arrow,
.global_navigation__anchor:hover .global_navigation__anchor__arrow {
  margin-left: 0;
}
.global_navigation__anchor__arrow__path {
  fill: #2b6462;
}
.global_navigation__anchor__text {
  will-change: padding-right;
  transition-duration: 0.28s;
  transition-property: padding-right;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  padding-left: 0.20225rem;
  padding-right: 1.441583333333333rem;
}
.global_navigation__anchor:active .global_navigation__anchor__text,
.global_navigation__anchor:focus .global_navigation__anchor__text,
.global_navigation__anchor:hover .global_navigation__anchor__text {
  padding-right: 0;
}
.logo_and_sign_in_status {
  z-index: 70;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: 1px solid #18191b;
  font-size: 0.892857142857143rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 1081px) {
  .logo_and_sign_in_status {
    width: 16rem;
  }
}
@media only screen and (min-width: 681px) {
  .logo_and_sign_in_status {
    font-size: 0.833333333333333rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .logo_and_sign_in_status {
    font-size: 0.78125rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.logo_and_sign_in_status__logo_anchor {
  z-index: 70;
  flex: 0 0 auto;
  display: flex;
  background-color: #3f4450;
  padding: 0.4045rem;
}
.logo_and_sign_in_status__logo {
  height: 3.86rem;
  width: 4.854rem;
  max-width: 100%;
}
.logo_and_sign_in_status__sign_in_status {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: #373b43;
  padding: 0.539333333333333rem 1.077588rem;
  text-align: center;
}
@media only screen and (min-width: 1081px) {
  .logo_and_sign_in_status__sign_in_status {
    justify-content: center;
    border-left: 1px solid #18191b;
  }
}
@media only screen and (min-width: 1081px) {
  .logo_and_sign_in_status__sign_in_status {
    padding: 0.539333333333333rem;
  }
}
.logo_and_sign_in_status__sign_in_status_user_name {
  word-break: break-all;
  text-shadow: 0 0 0.08em #18191b;
  color: #f4f6f9;
}
@media only screen and (min-width: 481px) and (max-width: 1080px) {
  .logo_and_sign_in_status__sign_in_status_user_name br {
    display: none;
  }
}
.logo_and_sign_in_status__sign_in_status_anchor {
  will-change: box-shadow, color;
  transition-duration: 0.28s;
  transition-property: box-shadow, color;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  flex: 0 0 auto;
  display: inline-block;
  box-shadow: inset 0 -3px rgba(255,255,255,0.2);
  color: #fff;
}
.logo_and_sign_in_status__sign_in_status_anchor:active,
.logo_and_sign_in_status__sign_in_status_anchor:focus,
.logo_and_sign_in_status__sign_in_status_anchor:hover {
  box-shadow: inset 0 -1px rgba(231,234,239,0.6);
  color: #e7eaef;
}
.main_content {
  flex: 1 1 auto;
  overflow-y: auto;
  width: 100%;
}
.main_content__inner {
  display: block;
  margin: 1.618rem auto;
  overflow-x: visible;
  width: 92%;
  max-width: 1520px;
}
@media only screen and (min-width: 1081px) {
  .main_content__inner {
    margin: 3.456rem auto 3.236rem;
  }
}
.main_content__inner > * {
  width: 100%;
}
.view_content {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  background-color: #f4f6f9;
}
@media only screen and (min-width: 881px) {
  .view_content {
    height: 100vh;
    max-height: 100vh;
  }
}
.action_anchor_container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -1.618rem;
  width: 100%;
}
* + .action_anchor_container {
  margin-top: 1.618rem;
}
.action_anchor_container + .action_anchor_container,
.button_container + .action_anchor_container {
  margin-top: 3.236rem;
}
.action_anchor {
  flex: 0 0 auto;
  will-change: color, border-color, box-shadow;
  transition-duration: 0.28s;
  transition-property: color, border-color, box-shadow;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: inset 0 0 0 #fff;
  border-bottom: 1px solid;
  padding: 0 0.539333333333333rem 0.269666666666667rem;
}
.action_anchor_container .action_anchor {
  margin-bottom: 1.618rem;
}
.action_anchor_container .action_anchor:not(:last-child) {
  margin-right: 1.618rem;
}
.action_anchor--primary_action {
  box-shadow: inset 0 0 0 #daf1f0;
  border-color: #319b98;
  color: #319b98;
}
.action_anchor--primary_action:active,
.action_anchor--primary_action:focus,
.action_anchor--primary_action:hover {
  box-shadow: inset 0 -0.66em 0 #daf1f0;
}
.action_anchor--primary_action .triangle__inner,
.action_anchor--primary_action .triangle__path {
  fill: #319b98;
}
.action_anchor--secondary_action {
  box-shadow: inset 0 0 0 #daf1f0;
  border-color: #7bc6c4;
  color: #319b98;
}
.action_anchor--secondary_action:active,
.action_anchor--secondary_action:focus,
.action_anchor--secondary_action:hover {
  box-shadow: inset 0 -0.66em 0 #daf1f0;
  color: #319b98;
}
.action_anchor--secondary_action .triangle__inner {
  fill: transparent;
}
.action_anchor--secondary_action .triangle__path {
  fill: #319b98;
}
.action_anchor--risk_action {
  box-shadow: inset 0 0 0 #ffebec;
  border-color: #fb0e16;
  color: #fb0e16;
}
.action_anchor--risk_action:active,
.action_anchor--risk_action:focus,
.action_anchor--risk_action:hover {
  box-shadow: inset 0 -0.66em 0 #ffebec;
}
.action_anchor--risk_action .triangle__inner,
.action_anchor--risk_action .triangle__path {
  fill: #fb0e16;
}
.action_anchor--reductive_action {
  box-shadow: inset 0 0 0 #ffebec;
  border-color: #fc4046;
  color: #fc4046;
}
.action_anchor--reductive_action:active,
.action_anchor--reductive_action:focus,
.action_anchor--reductive_action:hover {
  box-shadow: inset 0 -0.66em 0 #ffebec;
}
.action_anchor--reductive_action .triangle__inner {
  fill: transparent;
}
.action_anchor--reductive_action .triangle__path {
  fill: #fc4046;
}
.action_anchor--disabled {
  cursor: not-allowed;
  border-color: #5e636e;
  color: #5e636e;
}
.action_anchor--disabled:active,
.action_anchor--disabled:focus,
.action_anchor--disabled:hover {
  box-shadow: inset 0 0 0 #fff;
  border-color: #5e636e;
  color: #5e636e;
}
.action_anchor--backwards_action:active .triangle,
.action_anchor--backwards_action:focus .triangle,
.action_anchor--backwards_action:hover .triangle {
  animation: action-anchor-interaction-backwards 0.56s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.action_anchor--forwards_action:active .triangle,
.action_anchor--forwards_action:focus .triangle,
.action_anchor--forwards_action:hover .triangle {
  animation: action-anchor-interaction-forwards 0.56s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.action_anchor--downwards_action:active .triangle,
.action_anchor--downwards_action:focus .triangle,
.action_anchor--downwards_action:hover .triangle {
  animation: action-anchor-interaction-downwards 0.56s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.action_anchor__text {
  flex: 0 0 auto;
  word-break: keep-all;
}
.action_anchor .triangle {
  flex: 0 0 auto;
}
.action_anchor .triangle--right,
.action_anchor .triangle--left {
  height: 0.9em;
  width: 0.594em;
}
.action_anchor .triangle--right,
.action_anchor .triangle--down {
  margin-left: 0.4045rem;
}
.action_anchor .triangle--left {
  margin-right: 0.4045rem;
}
.action_anchor .triangle--down {
  height: 0.64em;
  width: 0.8512em;
}
.alerts__list {
  display: block;
  width: 100%;
}
* + .alerts__list {
  margin-top: 1.618rem;
}
.alerts__item {
  position: relative;
  z-index: auto;
  display: flex;
  flex-direction: column;
  border-radius: 2px 0 0 2px;
  border-left: 3px solid;
  padding: 2.0225rem 4vw 1.618rem;
  padding-right: calc(1.618rem + 4vw);
}
@media only screen and (min-width: 481px) {
  .alerts__item {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
* + .alerts__item {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 481px) {
  .alerts__item {
    padding: 2.0225rem 1.618rem 1.618rem;
  }
}
@media only screen and (min-width: 481px) {
  .alerts__item {
    padding-right: 2.695588rem;
  }
}
.alerts__item--success {
  border-left-color: #00c66d;
  background-color: hsla(153,100%,39%,0.09999999999999998);
}
.alerts__item--success .alerts__icon_path {
  fill: #00c66d;
}
.alerts__item--error {
  border-left-color: #ff295d;
  background-color: hsla(345.4,100%,58%,0.09999999999999998);
}
.alerts__item--error .alerts__icon_path {
  fill: #ff295d;
}
.alerts__item--info {
  border-left-color: #fb3;
  background-color: #e7eaef;
}
.alerts__item--info .alerts__icon_path {
  fill: #fb3;
}
.alerts__icon_container {
  box-shadow: 0 1px 3px rgba(33,35,39,0.1);
  overflow: hidden;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
}
@media only screen and (min-width: 481px) {
  .alerts__icon_container {
    flex: 0 0 auto;
  }
}
@media only screen and (min-width: 481px) {
  .alerts__icon_container {
    height: 3rem;
    width: 3rem;
  }
}
@media only screen and (min-width: 1081px) {
  .alerts__icon_container {
    height: 4rem;
    width: 4rem;
  }
}
.alerts__icon {
  transform: scale(1.02);
  background-color: #fff;
}
@media only screen and (min-width: 481px) {
  .alerts__details {
    flex: 1 1 auto;
  }
}
* + .alerts__details {
  margin-top: 1.077588rem;
}
@media only screen and (min-width: 481px) {
  * + .alerts__details {
    margin-top: 0;
  }
  * + .alerts__details {
    margin-left: 1.618rem;
  }
}
.alerts__status,
.alerts__message {
  display: block;
}
.alerts__status {
  max-width: 44.4ch;
  letter-spacing: -0.042em;
  font-family: 'inter-ui--medium', sans-serif;
  font-size: 1.404928rem;
  font-size: var(--fz-ratio-power--three);
  color: #212327;
}
@media only screen and (min-width: 681px) {
  .alerts__status {
    font-size: 1.728000000000001rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  .alerts__status {
    font-size: 2.097152rem;
    font-size: var(--fz-ratio-power--three);
  }
}
.alerts__message {
  max-width: 59.2ch;
  letter-spacing: -0.034em;
  font-size: 1.12rem;
  font-size: var(--fz-ratio-power--one);
  color: #3d4048;
}
* + .alerts__message {
  margin-top: 0.3236rem;
}
@media only screen and (min-width: 681px) {
  .alerts__message {
    font-size: 1.2rem;
    font-size: var(--fz-ratio-power--one);
  }
}
@media only screen and (min-width: 1281px) {
  .alerts__message {
    font-size: 1.28rem;
    font-size: var(--fz-ratio-power--one);
  }
}
.alerts__dismiss_button {
  position: absolute;
  z-index: auto;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.427rem;
  width: 2.427rem;
  background-color: #fff;
  padding: 0.539333333333333rem;
}
.alerts__dismiss_button_icon_path {
  fill: #319b98;
}
.alerts__dismiss_button:active .alerts__dismiss_button_icon_path,
.alerts__dismiss_button:focus .alerts__dismiss_button_icon_path,
.alerts__dismiss_button:hover .alerts__dismiss_button_icon_path {
  fill: #2b9794;
}
* + .blockquote {
  margin-top: 2.427rem;
}
.blockquote p {
  font-family: 'inter-ui--italic', sans-serif;
  line-height: 1.4;
  font-size: 1.404928rem;
  font-size: var(--fz-ratio-power--three);
  color: #5e636e;
}
@media only screen and (min-width: 681px) {
  .blockquote p {
    font-size: 1.728000000000001rem;
    font-size: var(--fz-ratio-power--three);
  }
}
@media only screen and (min-width: 1281px) {
  .blockquote p {
    font-size: 2.097152rem;
    font-size: var(--fz-ratio-power--three);
  }
}
.blockquote p::before {
  content: "“";
}
.blockquote cite {
  display: block;
  font-size: 1.2544rem;
  font-size: var(--fz-ratio-power--two);
  color: #212327;
}
* + .blockquote cite {
  margin-top: 0.809rem;
}
@media only screen and (min-width: 681px) {
  .blockquote cite {
    font-size: 1.44rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .blockquote cite {
    font-size: 1.6384rem;
    font-size: var(--fz-ratio-power--two);
  }
}
.blockquote cite::before {
  content: "—";
}
.breadcrumbs {
  width: 100%;
}
.breadcrumbs__list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.breadcrumbs__item {
  flex: 0 0 auto;
  font-size: 0;
}
.breadcrumbs__anchor {
  will-change: box-shadow, color;
  transition-duration: 0.28s;
  transition-property: box-shadow, color;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  font-size: 0.892857142857143rem;
  font-size: var(--fz-ratio-power--minus-one);
  box-shadow: inset 0 -3px rgba(49,155,152,0.2);
  color: #319b98;
}
@media only screen and (min-width: 681px) {
  .breadcrumbs__anchor {
    font-size: 0.833333333333333rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .breadcrumbs__anchor {
    font-size: 0.78125rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.breadcrumbs__anchor:active,
.breadcrumbs__anchor:focus,
.breadcrumbs__anchor:hover {
  box-shadow: inset 0 -1px rgba(43,151,148,0.6);
  color: #2b9794;
}
.breadcrumbs__next,
.breadcrumbs__this {
  flex: 0 0 auto;
  margin: 0 0.3236rem;
}
.breadcrumbs__next__path,
.breadcrumbs__this__path {
  fill: #5e636e;
}
.breadcrumbs__next {
  margin-bottom: 0.1rem;
  height: 0.38rem;
  width: 0.24rem;
}
.breadcrumbs__this {
  margin-bottom: 0.13rem;
  height: 0.24rem;
  width: 0.38rem;
}
.button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -1.618rem;
  width: 100%;
}
* + .button_container {
  margin-top: 1.618rem;
}
.button_container + .button_container,
.action_anchor_container + .button_container {
  margin-top: 3.236rem;
}
.button {
  will-change: color, background-color, border-color, box-shadow, text-shadow, transform;
  transition-duration: 0.28s;
  transition-property: color, background-color, border-color, box-shadow, text-shadow, transform;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid;
  padding: 0.5045rem 1.077588rem 0.539333333333333rem;
  word-break: keep-all;
}
.button:active,
.button:focus,
.button:hover {
  transform: translateY(-2px);
}
.button_container .button {
  margin-bottom: 1.618rem;
}
.button_container .button:not(:last-child) {
  margin-right: 1.618rem;
}
.button--primary_action {
  box-shadow: inset 0 -2px 0 #2b6462;
  border-color: #2b6462;
  background-color: #319b98;
  text-shadow: 0 0 0.08em #2b6462;
  color: #fff;
}
.button--primary_action:active,
.button--primary_action:focus,
.button--primary_action:hover {
  box-shadow: inset 0 -1px 0 #2b6462;
  background-color: #319b98;
}
.button--secondary_action {
  box-shadow: inset 0 -3px 0 #daf1f0;
  border-color: #319b98;
  background-color: #fff;
  color: #319b98;
}
.button--secondary_action:active,
.button--secondary_action:focus,
.button--secondary_action:hover {
  box-shadow: inset 0 -1px 0 #daf1f0;
  color: #319b98;
}
.button--reductive_action {
  box-shadow: inset 0 -3px 0 #ffebec;
  border-color: #fb0e16;
  background-color: #fff;
  color: #fb0e16;
}
.button--reductive_action:active,
.button--reductive_action:focus,
.button--reductive_action:hover {
  box-shadow: inset 0 -1px 0 #ffebec;
  border-color: #d3030a;
  color: #d3030a;
}
.button--risk_action {
  box-shadow: inset 0 -2px 0 #d3030a;
  border-color: #d3030a;
  background-color: #fb0e16;
  color: #fff;
}
.button--risk_action:active,
.button--risk_action:focus,
.button--risk_action:hover {
  box-shadow: inset 0 -1px 0 #d3030a;
}
.button--disabled {
  cursor: not-allowed;
  box-shadow: inset 0 -3px 0 #e7eaef;
  border-color: #5e636e;
  background-color: $cl--text-high;
  color: #5e636e;
}
.button--disabled:active,
.button--disabled:focus,
.button--disabled:hover {
  transform: translateY(0);
}
.definitions {
  box-shadow: 0 1px 3px rgba(33,35,39,0.04);
  width: 100%;
  border-radius: 2px 0 0 2px;
  border-left: 3px solid #fb3;
  background-color: #fff;
  padding: 1.2135rem 4vw 1.618rem;
}
* + .definitions {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 481px) {
  .definitions {
    padding: 1.2135rem 1.618rem 1.618rem;
  }
}
.definitions__heading {
  font-size: 1.2544rem;
  font-size: var(--fz-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  .definitions__heading {
    font-size: 1.44rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .definitions__heading {
    font-size: 1.6384rem;
    font-size: var(--fz-ratio-power--two);
  }
}
* + .definitions__list {
  margin-top: 1.2135rem;
}
.definitions__item {
  display: block;
}
* + .definitions__item {
  margin-top: 1.077588rem;
}
.definitions__term {
  font-family: 'inter-ui--medium', sans-serif;
}
.definitions__term::after {
  content: ":";
}
* + .definitions__description {
  margin-top: 0.539333333333333rem;
}
* + .definitions__description {
  margin-left: 1.618rem;
}
.definitions__description::before {
  content: "–";
  display: inline-block;
  margin-right: 0.539333333333333rem;
}
.document_download__anchor {
  will-change: background-color, transform;
  transition-duration: 0.28s;
  transition-property: background-color, transform;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  display: flex;
  flex-direction: column-reverse;
  box-shadow: 0 1px 3px rgba(33,35,39,0.04);
  max-width: 40rem;
  border-radius: 2px 0 0 2px;
  border-left: 3px solid #319b98;
  background-color: #daf1f0;
  padding: 1.618rem 4vw;
  text-align: left;
}
.document_download__anchor:active,
.document_download__anchor:focus,
.document_download__anchor:hover {
  transform: translateY(-0.269666666666667rem);
}
@media only screen and (min-width: 681px) {
  .document_download__anchor {
    flex-direction: row;
    align-items: flex-end;
  }
}
* + .document_download__anchor {
  margin-top: 1.077588rem;
}
@media only screen and (min-width: 681px) {
  .document_download__anchor {
    padding: 1.618rem;
  }
}
.document_download__anchor:active,
.document_download__anchor:focus,
.document_download__anchor:hover {
  border-left-color: $cl--$cl--anchor--interact;
}
.document_download__list {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  word-break: break-all;
}
@media only screen and (max-width: 680px) {
  .document_download__list {
    margin-top: 0.809rem;
  }
}
.document_download__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #50545e;
}
@media only screen and (min-width: 481px) {
  .document_download__item {
    flex-direction: row;
  }
}
* + .document_download__item {
  margin-top: 0.539333333333333rem;
}
.document_download__term {
  flex: 0 0 auto;
  font-family: 'inter-ui--medium', sans-serif;
}
@media only screen and (min-width: 481px) {
  .document_download__term {
    flex: 0 0 8rem;
  }
}
.document_download__description {
  flex: 0 0 auto;
}
@media only screen and (min-width: 481px) {
  .document_download__description {
    flex: 1 1 auto;
  }
  * + .document_download__description {
    margin-left: 0.809rem;
  }
}
.document_download__icon_container {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
}
@media only screen and (min-width: 681px) {
  * + .document_download__icon_container {
    margin-left: 1.618rem;
  }
}
.document_download__icon {
  height: auto;
  width: 2.427rem;
}
@media only screen and (min-width: 681px) {
  .document_download__icon {
    width: 3.236rem;
  }
}
.document_download__icon__background {
  will-change: fill;
  transition-duration: 0.28s;
  transition-property: fill;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  fill: #319b98;
}
.document_download__anchor:active .document_download__icon__background,
.document_download__anchor:focus .document_download__icon__background,
.document_download__anchor:hover .document_download__icon__background {
  fill: #2b9794;
}
.document_download__icon__letters {
  fill: #daf1f0;
}
.document_download__icon__arrow {
  fill: #fff;
}
.form_component {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: 3px solid #fb3;
  border-radius: 2px 0 0 2px;
  width: 100%;
  padding-bottom: 2px;
  padding-left: 1.077588rem;
}
*:not([type="hidden"]) + .form_component {
  margin-top: 1.618rem;
}
.form_component > * {
  flex: 0 0 auto;
}
.form_component__checkbox {
  position: relative;
  z-index: auto;
  display: block;
}
.form_component__checkbox + .form_component__checkbox {
  margin-top: 0.539333333333333rem;
}
.form_component__checkbox__input {
  cursor: pointer;
  z-index: auto;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 0;
}
.form_component__checkbox__label {
  cursor: pointer;
  position: relative;
  z-index: auto;
  display: inline-block;
  padding-left: 1.752833333333333rem;
}
.form_component__checkbox__label::before,
.form_component__checkbox__label::after {
  will-change: opacity, border-color, transform, zoom;
  transition-duration: 0.28s;
  transition-property: opacity, border-color, transform, zoom;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  content: "";
  position: absolute;
  display: inline-block;
}
.form_component__checkbox__label::before {
  top: 0.269666666666667rem;
  left: 0;
  height: 1.2135rem;
  width: 1.2135rem;
  border-radius: 2px;
  border: 1px solid #2b9794;
  background-color: #fff;
}
.form_component__checkbox__input:focus + .form_component__checkbox__label::before {
  z-index: 100;
  outline-offset: 6px;
  outline: 2px dashed #fb3;
  overflow: visible;
}
.form_component__checkbox__input:checked + .form_component__checkbox__label::before {
  border-color: #2b9794;
}
.form_component__checkbox__label::after {
  z-index: 110;
  transform: rotate(-10deg) scale(0.4);
  top: 0.58rem;
  left: 0.2rem;
  height: 0.4045rem;
  width: 0.809rem;
  border-bottom: 2px solid #2b9794;
  border-left: 2px solid #2b9794;
}
.form_component__checkbox__input + .form_component__checkbox__label::after {
  opacity: 0;
}
.form_component__checkbox__input:checked + .form_component__checkbox__label::after {
  opacity: 1;
  transform: rotate(-45deg) scale(1);
}
.form_component__checkbox__label a,
.form_component__checkbox__label a:visited {
  z-index: 110;
  position: relative;
  box-shadow: inset 0 -3px rgba(49,155,152,0.2);
  color: #319b98;
}
.form_component__checkbox__label a:active,
.form_component__checkbox__label a:visited:active,
.form_component__checkbox__label a:focus,
.form_component__checkbox__label a:visited:focus,
.form_component__checkbox__label a:hover,
.form_component__checkbox__label a:visited:hover {
  box-shadow: inset 0 -1px rgba(43,151,148,0.6);
  color: #2b9794;
}
.form_component--date {
  flex-direction: row;
  align-items: stretch;
}
.form_component--date .form_component__label {
  flex: 0 0 auto;
  position: relative;
  z-index: auto;
  width: auto;
  max-width: 100%;
  border-radius: 2px 0 0 2px;
  border: 1px solid #2b9794;
  border-right: 0;
  background-color: #fff;
  padding: 0.539333333333333rem;
  line-height: 1.618;
}
.form_component--date .form_component__label:first-child {
  margin-top: 0;
}
.form_component--date .form_component__label::before {
  content: '';
  position: absolute;
  z-index: auto;
  top: 20%;
  right: 0;
  height: 60%;
  width: 1px;
  background-color: #7bc6c4;
}
.form_component--date .form_component__label::after {
  content: ':';
}
.form_component__date {
  will-change: color, background-color, box-shadow, transform, opacity;
  transition-duration: 0.28s;
  transition-property: color, background-color, box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  flex: 1 1 auto;
  cursor: pointer;
  width: auto;
  max-width: 100%;
  box-shadow: none;
  border-radius: 0 2px 2px 0;
  border: 1px solid #2b9794;
  border-left: 0;
  background-color: #fff;
  padding: 0.539333333333333rem;
  line-height: 1.618;
}
.form_component__date::-webkit-input-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__date:-moz-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__date::-moz-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__date:-ms-input-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__date:active,
.form_component__date:focus,
.form_component__date:hover {
  outline: 0;
}
.form_component__date::-webkit-calendar-picker-indicator,
.form_component__date::-webkit-clear-button,
.form_component__date::-webkit-inner-spin-button {
  opacity: 1;
}
* + .form {
  margin-top: 1.618rem;
}
.form *:not(button):required:invalid:focus,
.form *:not(button):required:invalid:active {
  border: 1px solid #ff295d;
}
.form *:not(button):required:valid:focus,
.form *:not(button):required:valid:active {
  border: 1px solid #00c66d;
}
.form_component__label {
  display: block;
  width: 100%;
  max-width: 74ch;
  color: #3d4048;
}
.form_component__label:first-child {
  margin-top: -0.3236rem;
}
.filter_form .form_component__label {
  width: auto;
  max-width: 100%;
}
.form_component__label--error {
  padding-left: 1.077588rem;
  color: #ff295d;
}
* + .form_component__label--error {
  margin-top: 0.539333333333333rem;
}
.form_component__label--error li {
  font-size: 0.892857142857143rem;
  font-size: var(--fz-ratio-power--minus-one);
}
* + .form_component__label--error li {
  margin-top: 0.539333333333333rem;
}
@media only screen and (min-width: 681px) {
  .form_component__label--error li {
    font-size: 0.833333333333333rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .form_component__label--error li {
    font-size: 0.78125rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.form_component__label--error li::before {
  content: "↑ ";
  color: hsla(345.4,100%,58%,0.4);
}
.form_component__number {
  will-change: color, background-color, box-shadow, transform, opacity;
  transition-duration: 0.28s;
  transition-property: color, background-color, box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  width: 74ch;
  max-width: 100%;
  box-shadow: none;
  border-radius: 2px;
  border: 1px solid #2b9794;
  background-color: #fff;
  padding: 0.539333333333333rem;
}
.form_component__number::-webkit-input-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__number:-moz-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__number::-moz-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__number:-ms-input-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__number:active,
.form_component__number:focus,
.form_component__number:hover {
  outline: 0;
}
label + .form_component__number {
  margin-top: 0.539333333333333rem;
}
.form_component__password {
  will-change: color, background-color, box-shadow, transform, opacity;
  transition-duration: 0.28s;
  transition-property: color, background-color, box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  width: 74ch;
  max-width: 100%;
  box-shadow: none;
  border-radius: 2px;
  border: 1px solid #2b9794;
  background-color: #fff;
  padding: 0.539333333333333rem;
}
.form_component__password::-webkit-input-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__password:-moz-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__password::-moz-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__password:-ms-input-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__password:active,
.form_component__password:focus,
.form_component__password:hover {
  outline: 0;
}
label + .form_component__password {
  margin-top: 0.539333333333333rem;
}
.form_component__radio {
  position: relative;
  z-index: auto;
  display: flex;
  width: 100%;
  max-width: 74ch;
}
.form_component__radio + .form_component__radio {
  margin-top: 0.539333333333333rem;
}
.form_component__radio__input {
  cursor: pointer;
  z-index: 10;
  position: absolute;
  opacity: 0;
  margin: 0;
  border: 0;
  height: 100%;
  width: 100%;
  padding: 0;
}
.form_component__radio__label {
  position: relative;
  z-index: auto;
  display: inline-block;
  padding-left: calc(0.539333333333333rem + 20px);
  color: #3d4048;
}
@media only screen and (max-width: 1080px) {
  .form_component__radio__label {
    padding-top: 0.20225rem;
  }
}
.form_component__radio__label::before,
.form_component__radio__label::after {
  will-change: opacity, border-color, transform, scale;
  transition-duration: 0.28s;
  transition-property: opacity, border-color, transform, scale;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  content: "";
  position: absolute;
  display: inline-block;
}
.form_component__radio__label::before {
  top: 0.20225rem;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid #2b9794;
  background-color: #fff;
}
.form_component__radio__input:focus + .form_component__radio__label::before {
  z-index: 100;
  outline-offset: 6px;
  outline: 2px dashed #fb3;
  overflow: visible;
}
.form_component__radio__input:checked + .form_component__radio__label::before,
.form_component__radio__input:focus:checked + .form_component__radio__label::before {
  border-color: #2b9794;
}
.form_component__radio__label::after {
  transform: scale(0.1);
  top: calc(0.20225rem + 5px);
  left: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 0;
  background-color: #2b9794;
}
.form_component__radio__input + .form_component__radio__label::after {
  opacity: 0;
}
.form_component__radio__input:checked + .form_component__radio__label::after {
  z-index: 110;
  opacity: 1;
  transform: scale(0.8);
}
.form_component__select_container {
  position: relative;
  z-index: auto;
  width: 100%;
  max-width: 74ch;
}
.filter_form .form_component__select_container {
  width: auto;
}
.form_component__select_container::before {
  content: '▼';
  pointer-events: none;
  transform: scale(1.5, 1) translateX(-10%) translateY(-50%);
  position: absolute;
  top: 50%;
  right: 0.809rem;
  color: #2b9794;
}
label + .form_component__select_container {
  margin-top: 0.539333333333333rem;
}
.form_component__select {
  will-change: border-color, box-shadow;
  transition-duration: 0.28s;
  transition-property: border-color, box-shadow;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  cursor: pointer;
  appearance: none;
  width: 100%;
  border-radius: 2px;
  border: 1px solid #2b9794;
  background-color: #fff;
  background-position: right 0.809rem top 50%;
  background-repeat: no-repeat;
  background-size: 1.2135rem;
  padding: 0.539333333333333rem 2.427rem 0.539333333333333rem 0.6472rem;
  padding-bottom: calc(0.539333333333333rem + 3px);
  color: $cl--dark--low;
}
.form_component__select:active,
.form_component__select:focus,
.form_component__select:hover {
  outline: 0;
  border-color: $cl--interaction--first;
}
.filter_form .form_component__select {
  padding: 0.269666666666667rem 2.157333333333333rem 0.4045rem 0.269666666666667rem;
}
.form_component__tel {
  will-change: color, background-color, box-shadow, transform, opacity;
  transition-duration: 0.28s;
  transition-property: color, background-color, box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  width: 74ch;
  max-width: 100%;
  box-shadow: none;
  border-radius: 2px;
  border: 1px solid #2b9794;
  background-color: #fff;
  padding: 0.539333333333333rem;
}
.form_component__tel::-webkit-input-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__tel:-moz-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__tel::-moz-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__tel:-ms-input-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__tel:active,
.form_component__tel:focus,
.form_component__tel:hover {
  outline: 0;
}
label + .form_component__tel {
  margin-top: 0.539333333333333rem;
}
.form_component__text {
  will-change: color, background-color, box-shadow, transform, opacity;
  transition-duration: 0.28s;
  transition-property: color, background-color, box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  width: 74ch;
  max-width: 100%;
  box-shadow: none;
  border-radius: 2px;
  border: 1px solid #2b9794;
  background-color: #fff;
  padding: 0.539333333333333rem;
}
.form_component__text::-webkit-input-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__text:-moz-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__text::-moz-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__text:-ms-input-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__text:active,
.form_component__text:focus,
.form_component__text:hover {
  outline: 0;
}
label + .form_component__text {
  margin-top: 0.539333333333333rem;
}
.form_component__textarea {
  will-change: color, background-color, box-shadow, transform, opacity;
  transition-duration: 0.28s;
  transition-property: color, background-color, box-shadow, transform, opacity;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  resize: none;
  width: 74ch;
  max-width: 100%;
  box-shadow: none;
  border-radius: 2px;
  border: 1px solid #2b9794;
  background-color: #fff;
  padding: 0.539333333333333rem;
}
.form_component__textarea::-webkit-input-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__textarea:-moz-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__textarea::-moz-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__textarea:-ms-input-placeholder {
  color: hsla(220.7,8%,34%,0.6);
  opacity: 1;
}
.form_component__textarea:active,
.form_component__textarea:focus,
.form_component__textarea:hover {
  outline: 0;
}
label + .form_component__textarea {
  margin-top: 0.539333333333333rem;
}
.media_embed {
  width: 100%;
  height: auto;
}
* + .media_embed {
  margin-top: 2.427rem;
}
.media_embed__item {
  padding: 56.25% 0 0 0;
  position: relative;
  z-index: auto;
}
.media_embed__iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.pagination {
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  overflow: hidden;
  width: 100%;
  max-width: 19.416rem;
}
* + .pagination {
  margin-top: 2.427rem;
}
.pagination__anchor {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3.236rem;
  width: 3.236rem;
  background-color: #3f4450;
  padding: 0.4045rem 0.809rem;
}
.pagination__anchor:active,
.pagination__anchor:focus,
.pagination__anchor:hover {
  background-color: #dfe2e7;
}
.pagination__anchor svg {
  width: 100%;
  max-width: 100%;
  height: 100%;
}
.pagination__anchor--previous svg {
  transform: rotate(180deg);
}
.pagination__anchor .icon__arrow path {
  fill: #50545e;
}
.pagination__summary {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #18191b;
  color: #50545e;
}
* + .purchase_details {
  margin-top: 1.618rem;
}
.table_wrap {
  overflow-x: auto;
  border-radius: 2px;
  width: 100%;
  max-width: 100%;
  box-shadow: 0 1px 3px rgba(33,35,39,0.12);
}
* + .table_wrap {
  margin-top: 1.618rem;
}
table {
  display: table;
  table-layout: auto;
  border-collapse: collapse;
  width: fit-content;
  width: 100%;
  max-width: 100%;
}
table th,
table td {
  min-width: 180px;
  padding: 0.809rem;
}
table th {
  text-align: left;
  font-family: 'inter-ui--medium', sans-serif;
  color: #fff;
}
@media only screen and (min-width: 881px) {
  table thead {
    border: 1px solid #18191b;
    border-bottom: 0;
  }
}
table thead th {
  vertical-align: bottom;
  background-color: #3f4450;
  text-shadow: 0 0 0.1em #18191b;
}
table thead th + th {
  border-left: 1px solid #18191b;
}
table tbody {
  min-width: 100%;
}
@media only screen and (min-width: 881px) {
  table tbody {
    border: 1px solid #dfe2e7;
    border-top: 0;
  }
}
table tbody th {
  vertical-align: top;
  border-top: 1px solid #18191b;
  border-right: 1px solid #18191b;
  font-family: $fw--heading;
}
table tbody tr + tr {
  border-top: 1px solid #dfe2e7;
}
table tbody td:nth-child(even) {
  background: #fff;
}
table tbody td + td {
  border-left: 1px solid #dfe2e7;
}
table a,
table a:visited {
  box-shadow: inset 0 -3px rgba(49,155,152,0.2);
  color: #319b98;
}
table a:active,
table a:visited:active,
table a:focus,
table a:visited:focus,
table a:hover,
table a:visited:hover {
  box-shadow: inset 0 -1px rgba(43,151,148,0.6);
  color: #2b9794;
}
* + .terms_and_conditions {
  margin-top: 1.618rem;
}
.terms_and_conditions__summary {
  display: inline-block;
}
.terms_and_conditions__summary::-webkit-details-marker {
  display: none;
}
.terms_and_conditions__summary__button {
  pointer-events: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.terms_and_conditions__summary__button__text,
.terms_and_conditions__summary__button .triangle {
  flex: 0 0 auto;
}
.terms_and_conditions__summary__button .triangle {
  will-change: transform;
  transition-duration: 0.28s;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  flex: 0 0 auto;
  display: inline-block;
  margin-left: 0.539333333333333rem;
  height: 0.64em;
  width: 0.8512em;
}
.terms_and_conditions__summary__button .triangle__inner,
.terms_and_conditions__summary__button .triangle__path {
  fill: #319b98;
}
details[open] .terms_and_conditions__summary__button .triangle {
  transform: rotate(180deg);
}
* + .terms_and_conditions__content {
  margin-top: 1.618rem;
}
* + .view_heading_and_summary {
  margin-top: 1.077588rem;
}
.alerts__list + .view_heading_and_summary {
  margin-top: 1.618rem;
}
.view_heading_and_summary__summary {
  max-width: 51.8ch;
  font-size: 1.2544rem;
  font-size: var(--fz-ratio-power--two);
  letter-spacing: -0.053em;
}
* + .view_heading_and_summary__summary {
  margin-top: 1.077588rem;
}
@media only screen and (min-width: 681px) {
  .view_heading_and_summary__summary {
    font-size: 1.44rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .view_heading_and_summary__summary {
    font-size: 1.6384rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (max-width: 880px) {
  .establishment_licenses__table_wrap {
    overflow: visible;
    box-shadow: none;
  }
}
@media only screen and (max-width: 880px) {
  .establishment_licenses__table {
    display: block;
    width: 100%;
  }
}
@media only screen and (max-width: 880px) {
  .establishment_licenses__table_head {
    display: none;
  }
}
@media only screen and (max-width: 880px) {
  .establishment_licenses__table_body,
  .establishment_licenses__table_row,
  .establishment_licenses__table_data {
    display: block;
    width: 100%;
  }
}
@media only screen and (max-width: 880px) {
  .establishment_licenses__table_row {
    box-shadow: 0 1px 3px rgba(33,35,39,0.12);
  }
}
@media only screen and (max-width: 880px) {
  .establishment_licenses__table_row + .establishment_licenses__table_row {
    margin-top: 1.618rem;
    border-top: 0;
  }
}
@media only screen and (max-width: 880px) {
  .establishment_licenses__table_data::before {
    content: attr(aria-label);
    display: block;
    margin-bottom: 0.3236rem;
    font-family: 'inter-ui--medium', sans-serif;
  }
}
.members_detail__username_heading {
  display: inline;
}
.members_detail__permissions_list,
.members_detail__permissions_item {
  display: inline-block;
}
.product_detail__product_category {
  display: inline-block;
  border-left: 3px solid #fb3;
  border-radius: 2px 0 0 2px;
  background-color: #fec;
  padding: 0.1618rem 0.539333333333333rem;
  text-transform: uppercase;
  text-shadow: 0 0 -0.24em #eb9c00;
  font-size: 0.892857142857143rem;
  font-size: var(--fz-ratio-power--minus-one);
  letter-spacing: 0.014em;
}
* + .product_detail__product_category {
  margin-top: 1.077588rem;
}
@media only screen and (min-width: 681px) {
  .product_detail__product_category {
    font-size: 0.833333333333333rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .product_detail__product_category {
    font-size: 0.78125rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.product_detail__product_notice_and_media {
  display: block;
}
* + .product_detail__product_notice_and_media {
  margin-top: 2.427rem;
}
.product_detail__media {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.product_detail__media_embed {
  position: relative;
  z-index: auto;
  overflow: hidden;
  height: 0;
  width: 100%;
  max-width: 100%;
  background-color: #212327;
  padding-bottom: 56.25%;
}
.product_detail__media_embed__iframe {
  position: absolute;
  z-index: auto;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.product_detail__media_descriptor {
  display: inline-block;
  box-shadow: 0 1px 3px rgba(33,35,39,0.04);
  border-left: 3px solid #fb3;
  background-color: #fff;
  padding: 0.4045rem 0.809rem;
  text-transform: uppercase;
  color: #3d4048;
  font-size: 0.892857142857143rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 681px) {
  .product_detail__media_descriptor {
    font-size: 0.833333333333333rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .product_detail__media_descriptor {
    font-size: 0.78125rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.product_detail__product_image_container,
.product_detail__product_image {
  width: 100%;
}
* + .product_detail__product_description {
  margin-top: 1.618rem;
}
.product_detail__product_description img {
  width: auto;
}
* + .product_detail__supporting_resources {
  margin-top: 1.618rem;
}
.product_detail__supporting_resources_heading {
  font-size: 1.2544rem;
  font-size: var(--fz-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  .product_detail__supporting_resources_heading {
    font-size: 1.44rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .product_detail__supporting_resources_heading {
    font-size: 1.6384rem;
    font-size: var(--fz-ratio-power--two);
  }
}
.product_detail__purchase {
  box-shadow: 0 1px 3px rgba(33,35,39,0.04);
  width: 100%;
  border-radius: 2px 0 0 2px;
  border-left: 3px solid #fb3;
  background-color: #fff;
  padding: 1.618rem 4vw;
}
* + .product_detail__purchase {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 681px) {
  .product_detail__purchase {
    padding: 1.618rem;
  }
}
.product_detail__purchase_heading {
  font-size: 1.2544rem;
  font-size: var(--fz-ratio-power--two);
}
@media only screen and (min-width: 681px) {
  .product_detail__purchase_heading {
    font-size: 1.44rem;
    font-size: var(--fz-ratio-power--two);
  }
}
@media only screen and (min-width: 1281px) {
  .product_detail__purchase_heading {
    font-size: 1.6384rem;
    font-size: var(--fz-ratio-power--two);
  }
}
.product_detail__purchase_expiry_date {
  font-family: 'inter-ui--medium', sans-serif;
}
.products__list {
  display: block;
}
* + .products__list {
  margin-top: 3.236rem;
}
.products__item {
  position: relative;
  z-index: auto;
  display: block;
  width: 100%;
}
* + .products__item {
  margin-top: 1.618rem;
}
.products__anchor {
  will-change: box-shadow, border-width, transform;
  transition-duration: 0.28s;
  transition-property: box-shadow, border-width, transform;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 0 #7bc6c4;
  background-color: #daf1f0;
  padding: 1.618rem;
}
.products__anchor:active,
.products__anchor:focus,
.products__anchor:hover {
  transform: translateY(-3px);
}
@media only screen and (min-width: 881px) {
  .products__anchor {
    flex-direction: row;
  }
}
@media only screen and (min-width: 881px) {
  .products__anchor {
    align-items: stretch;
  }
}
.products__anchor:active,
.products__anchor:focus,
.products__anchor:hover {
  border-left: 0 solid #daf1f0;
  box-shadow: -3px 3px 0 #7bc6c4;
}
.products__details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
@media only screen and (min-width: 881px) {
  .products__details {
    flex: 1 1 50%;
  }
}
.products__category {
  will-change: border-width;
  transition-duration: 0.28s;
  transition-property: border-width;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  display: block;
  margin-top: -0.269666666666667rem;
  margin-bottom: auto;
  width: 100%;
  border-bottom: 3px solid #fff;
  padding-bottom: 0.4045rem;
  text-transform: uppercase;
  letter-spacing: 0.014em;
  color: #3d4048;
  font-size: 0.892857142857143rem;
  font-size: var(--fz-ratio-power--minus-one);
}
@media only screen and (min-width: 681px) {
  .products__category {
    font-size: 0.833333333333333rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
@media only screen and (min-width: 1281px) {
  .products__category {
    font-size: 0.78125rem;
    font-size: var(--fz-ratio-power--minus-one);
  }
}
.products__summary,
.products__price {
  display: block;
  color: #50545e;
}
* + .products__summary,
* + .products__price {
  margin-top: 1.077588rem;
}
.products__summary {
  max-width: 100%;
}
.products__price {
  font-family: 'inter-ui--medium', sans-serif;
}
.products__price::before {
  content: "";
  display: inline-block;
  margin-right: 0.539333333333333rem;
  margin-bottom: 0.12rem;
  height: 0.4045rem;
  width: 0.4045rem;
  border-radius: 50%;
  background-color: #fc6;
}
.products__price__number {
  box-shadow: inset 0 -0.18em 0 #fff;
}
.products__image_container {
  display: block;
  max-height: 30.4rem;
  max-width: 100%;
}
@media only screen and (min-width: 881px) {
  .products__image_container {
    flex: 0 1 50%;
  }
}
* + .products__image_container {
  margin-top: 1.618rem;
}
@media only screen and (min-width: 881px) {
  * + .products__image_container {
    margin-top: 0;
  }
  * + .products__image_container {
    margin-left: 1.618rem;
  }
}
@media only screen and (min-width: 881px) {
  .products__image_container {
    padding-bottom: 0.55rem;
  }
}
.products__image {
  margin-right: auto;
  margin-left: auto;
  box-shadow: -3px 3px 0 #7bc6c4;
  max-height: calc(30rem - 3px);
  max-width: calc(100% - 3px);
}
@media only screen and (min-width: 881px) {
  .products__image {
    margin-right: 0;
  }
}
.products__call_to_action {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;
  padding: 0.539333333333333rem 1.077588rem;
  color: #50545e;
}
@media only screen and (max-width: 880px) {
  .products__call_to_action {
    flex: 0 0 auto;
  }
}
@media only screen and (min-width: 881px) {
  .products__call_to_action {
    position: absolute;
    z-index: auto;
    right: 0.809rem;
    bottom: 0.809rem;
  }
}
@media only screen and (max-width: 880px) {
  * + .products__call_to_action {
    margin-top: 1.618rem;
  }
}
.products__call_to_action .triangle {
  flex: 0 0 auto;
  margin-left: 0.4045rem;
  height: 0.9em;
  width: 0.594em;
}
.products__call_to_action .triangle__inner,
.products__call_to_action .triangle__path {
  fill: #7bc6c4;
}
.products__anchor:active .products__call_to_action .triangle,
.products__anchor:focus .products__call_to_action .triangle,
.products__anchor:hover .products__call_to_action .triangle {
  animation: action-anchor-interaction-forwards 0.56s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.sign_in__request_account {
  display: block;
}
* + .sign_in__request_account {
  margin-top: 1.618rem;
}
.sign_in__request_account a,
.sign_in__request_account a:visited {
  box-shadow: inset 0 -3px rgba(49,155,152,0.2);
  color: #319b98;
}
.sign_in__request_account a:active,
.sign_in__request_account a:visited:active,
.sign_in__request_account a:focus,
.sign_in__request_account a:visited:focus,
.sign_in__request_account a:hover,
.sign_in__request_account a:visited:hover {
  box-shadow: inset 0 -1px rgba(43,151,148,0.6);
  color: #2b9794;
}
